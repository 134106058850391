<template>
  <v-container
    v-if="!affairLoading"
    fluid
    class="affair-detail"
  >
    <div
      class="text-right d-print-none"
    >
      <core-subscribe-button
        :affair="affair"
        @subscribed="onSubscribed()"
        @unsubscribed="onUnsubscribed()"
      />
      <core-assign-button
        v-if="hasPermission(permissions.SUBSCRIBERS)"
        module="Subscribers"
        :affair="affair"
        icon="$vuetify.icons.users"
        :label-accessor="item => `${item.name} ${item.surname}`"
      />
      <core-assign-button
        v-if="hasPermission(permissions.GLOBAL_ISSUES)"
        :affair="affair"
        :create-item-route="{ name: 'issues' }"
        module="GlobalIssues"
        icon="$vuetify.icons.userTag"
      />
      <core-assign-button
        v-if="hasPermission(permissions.AFFAIRS_ISSUES)"
        :affair="affair"
        module="Issues"
        :create-item-route="{ name: 'issues' }"
        icon="$vuetify.icons.tag"
      />
      <core-comment-section-button
        v-if="hasPermission(permissions.COMMENTS)"
        :affair="affair"
      />
    </div>
    <v-row>
      <v-col :md="commentSectionOpen ? 6 : undefined">
        <v-chip
          v-if="affair.affair_number"
          class="h3 mb-1"
          color="primary"
          text-color="white"
        >
          {{ affair.affair_number }}
        </v-chip>
        <h1>{{ affair.title }}</h1>
        <v-card>
          <v-card-text>
            <core-affair-detail-info-two-column
              :label-first-col="$t('AffairDetail.affairType')"
              :label-second-col="$t('AffairDetail.politicalLevel')"
            >
              <template v-slot:first-col>
                {{ affair.affair_type ? affair.affair_type.name : '-' }}
              </template>
              <template v-slot:second-col>
                {{ affair.political_level ? affair.political_level.name : '-' }}
              </template>
            </core-affair-detail-info-two-column>
            <core-affair-detail-info-two-column
              :label-first-col="$t('AffairDetail.session')"
              :label-second-col="$t('AffairDetail.submissionDate')"
            >
              <template v-slot:first-col>
                {{ affair.session ? affair.session.name : '-' }}
              </template>
              <template v-slot:second-col>
                {{ affair.submission_date ? $options.filters.formatDate(affair.submission_date) : '-' }}
              </template>
            </core-affair-detail-info-two-column>
            <core-affair-detail-info-two-column
              :label-first-col="$t('AffairDetail.firstCouncil')"
              :label-second-col="$t('AffairDetail.author')"
            >
              <template v-slot:first-col>
                {{ affair.first_council ? affair.first_council : '-' }}
              </template>
              <template v-slot:second-col>
                {{ affair.author ? affair.author.name : '-' }}
              </template>
            </core-affair-detail-info-two-column>
            <core-affair-detail-info
              v-if="affair.department"
              :label="$t('AffairDetail.department')"
            >
              {{ affair.department }}
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="affair.coauthors && affair.coauthors.length > 0"
              :label="$t('AffairDetail.coAuthors')"
            >
              {{ affair.coauthors.map(coauthor => coauthor.name).join(', ') }}
            </core-affair-detail-info>
            <core-affair-detail-info
              :label="$t('AffairDetail.topics')"
            >
              <div
                v-if="affair.topics && affair.topics.length > 0"
              >
                <v-chip
                  v-for="topic in affair.topics"
                  :key="topic.id"
                  class="ma-1"
                >
                  {{ topic.name }}
                </v-chip>
              </div>
              <template v-else>-</template>
            </core-affair-detail-info>
            <core-affair-detail-info
              :label="$t('AffairDetail.globalIssues')"
            >
              <div
                v-if="affair.global_issues && affair.global_issues.length > 0"
              >
                <v-chip
                  v-for="globalIssue in affair.global_issues"
                  :key="globalIssue.id"
                  class="ma-1"
                >
                  {{ globalIssue.name }}
                </v-chip>
              </div>
              <template v-else>-</template>
            </core-affair-detail-info>
            <core-affair-detail-info
              :label="$t('AffairDetail.issues')"
            >
              <div
                v-if="affair.issues && affair.issues.length > 0"
              >
                <v-chip
                  v-for="issue in affair.issues"
                  :key="issue.id"
                  class="ma-1"
                >
                  {{ issue.name }}
                </v-chip>
              </div>
              <template v-else>-</template>
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="affair.related_affairs && affair.related_affairs.length > 0"
              :label="$t('AffairDetail.relatedAffairs')"
            >
              <ul
                class="fa-ul"
              >
                <li
                  v-for="relatedAffair in affair.related_affairs"
                  :key="relatedAffair.id"
                >
                  <span class="fa-li"><v-icon small>$vuetify.icons.circleRegular</v-icon></span>
                  <router-link :to="{ name: 'affair-detail', params: { id: relatedAffair.id }}">
                    {{ relatedAffair.affair_number ? relatedAffair.affair_number + ' ' : '' }}{{ relatedAffair.title }}
                  </router-link>
                </li>
              </ul>
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="affair.depreciation_proposals && affair.depreciation_proposals.length > 0"
              :label="$t('AffairDetail.depreciationProposals')"
            >
              <ul
                class="fa-ul"
              >
                <li
                  v-for="depreciationProposal in affair.depreciation_proposals"
                  :key="depreciationProposal.id"
                >
                  <span class="fa-li"><v-icon small>$vuetify.icons.circleRegular</v-icon></span>
                  <router-link :to="{ name: 'affair-detail', params: { id: depreciationProposal.id }}">
                    {{ depreciationProposal.affair_number ? depreciationProposal.affair_number + ' ' : '' }}{{ depreciationProposal.title }}
                  </router-link>
                </li>
              </ul>
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="affair.url"
              :label="$t('AffairDetail.url')"
            >
              <a
                :href="affair.url"
                class="text-break"
              >
                <v-icon
                  class="ml-4 mr-1"
                  small
                >
                  $vuetify.icons.link
                </v-icon>
                {{ affair.url }}
              </a>
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="affair.messages && affair.messages.length > 0"
              :label="$t('AffairDetail.messages')"
            >
              <ul
                class="fa-ul"
              >
                <li
                  v-for="message in affair.messages"
                  :key="message.message_url"
                >
                  <span class="fa-li"><v-icon small>$vuetify.icons.comment</v-icon></span>
                  <a :href="message.message_url">
                    {{ message.message_title ? message.message_title : message.message_url }}
                  </a>
                </li>
              </ul>
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="affair.drafts && affair.drafts.length > 0"
              :label="$t('AffairDetail.drafts')"
            >
              <ul
                class="fa-ul"
              >
                <li
                  v-for="draft in affair.drafts"
                  :key="draft.draft_url"
                >
                  <span class="fa-li"><v-icon small>$vuetify.icons.fileAlt</v-icon></span>
                  <a :href="draft.draft_url">
                    {{ draft.draft_title ? draft.draft_title : draft.draft_url }}
                  </a>
                </li>
              </ul>
            </core-affair-detail-info>
            <core-affair-detail-info-two-column
              :label-first-col="$t('AffairDetail.state')"
              :label-second-col="$t('AffairDetail.nextState')"
            >
              <template v-slot:first-col>
                {{ affair.state ? affair.state.name : '-' }}
              </template>
              <template v-slot:second-col>
                {{ affair.next_state ? affair.next_state.name : '-' }}
              </template>
            </core-affair-detail-info-two-column>
            <core-affair-detail-info
              :label="$t('AffairDetail.description')"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="affair.description ? $options.filters.nl2br(affair.description) : '-'" />
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="affair.reason"
              :label="$t('AffairDetail.reason')"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="$options.filters.nl2br(affair.reason)" />
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="affair.files && affair.files.length > 0"
              :label="$t('AffairDetail.files')"
            >
              <ul
                class="fa-ul"
              >
                <li
                  v-for="file in affair.files"
                  :key="file.file_url"
                >
                  <span class="fa-li"><v-icon small>$vuetify.icons.paperclip</v-icon></span>
                  <a :href="file.file_url">
                    {{ file.file_title ? file.file_title : file.file_url }}
                  </a>
                </li>
              </ul>
            </core-affair-detail-info>
          </v-card-text>
        </v-card>

        <h2
          v-if="affair.events && affair.events.length > 0"
          class="mt-12"
        >
          {{ $t('AffairDetail.events.title') }}
        </h2>
        <v-card
          v-for="event in affair.events"
          :id="`event-${event.id}`"
          :key="event.id"
          class="mb-3 event"
        >
          <v-card-text>
            <h3>
              <div class="eventdate">{{ $options.filters.getFormattedEventdate(event.eventdate, event.enddate) }}</div>
              <template v-if="event.event_type">
                <div class="event-type text-break">{{ event.event_type.name }}</div>
              </template>
            </h3>
            <core-affair-detail-info
              v-if="event.author"
              :label="$t('AffairDetail.events.author')"
            >
              {{ event.author.name }}
            </core-affair-detail-info>
            <core-affair-detail-info
              :label="$t('AffairDetail.events.description')"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="event.description ? $options.filters.nl2br(event.description) : '-'" />
            </core-affair-detail-info>
            <core-affair-detail-info
              v-if="event.files && event.files.length > 0"
              :label="$t('AffairDetail.events.files')"
            >
              <ul
                class="fa-ul"
              >
                <li
                  v-for="file in event.files"
                  :key="file.file_url"
                >
                  <span class="fa-li"><v-icon small>$vuetify.icons.paperclip</v-icon></span>
                  <a :href="file.file_url">
                    {{ file.file_title }}
                  </a>
                </li>
              </ul>
            </core-affair-detail-info>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="commentSectionOpen"
        cols="12"
        md="6"
      >
        <core-comment-section :affair="affair" />
      </v-col>
    </v-row>
  </v-container>
  <v-container
    v-else
    fluid
    class="text-center"
  >
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { COMMENT_SECTION_HASH, isCommentSectionOpen } from '@/utils/comments';

export default {
  metaInfo () {
    return {
      title: this.affair.title,
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      // access to component's instance using `vm`.
      // this is done because this navigation guard is called before the component is created.
      await vm.getAffair(to.params.id)
      if (vm.$store.state.affairs.affairError) {
        vm.handleLoadingError()
      } else if (to.hash) {
        // enforce opening of comment section to be able to scroll to the section (because commentSectionOpen() is not evaluated yet)
        if (to.hash === COMMENT_SECTION_HASH) {
          vm.enforceCommentSectionOpen = true
        }

        // scroll to hash if available
        vm.$nextTick(function () {
          vm.$vuetify.goTo(to.hash)
          vm.enforceCommentSectionOpen = false
        })
      }

      next()
    })
  },
  async beforeRouteUpdate (to, from, next) {
    await this.getAffair(to.params.id)
    if (this.$store.state.affairs.affairError) {
      this.handleLoadingError()
    } else if (to.hash) {
      // enforce opening of comment section to be able to scroll to the section (because commentSectionOpen() is not evaluated yet)
      if (to.hash === COMMENT_SECTION_HASH) {
        this.enforceCommentSectionOpen = true
      }

      // scroll to hash if available
      this.$nextTick(function () {
        this.$vuetify.goTo(to.hash)
        this.enforceCommentSectionOpen = false
      })
    }

    next()
  },
  data () {
    return {
      enforceCommentSectionOpen:  false,
    }
  },
  computed: {
    ...mapGetters('affairs', [
      'affairLoading',
    ]),
    affair () {
      return this.$store.getters['affairs/affair'](parseInt(this.$route.params.id))
    },
    commentSectionOpen () {
      return isCommentSectionOpen(this.$route) || this.enforceCommentSectionOpen
    },
  },
  methods: {
    ...mapActions('affairs', ['getAffair']),
    ...mapMutations('affairs', [
      'SET_AFFAIRS_SUBSCRIBED',
    ]),
    handleLoadingError () {
      if (this.$store.state.affairs.affairError.response.status === 404) {
        this.$router.replace({ name: '404' })
      } else {
        this.$store.commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true })
      }
    },
    onSubscribed () {
      this.SET_AFFAIRS_SUBSCRIBED({ affairId: this.affair.id, subscribed: true })
    },
    onUnsubscribed () {
      this.SET_AFFAIRS_SUBSCRIBED({ affairId: this.affair.id, subscribed: false })
    },
  },
}
</script>
